// .rdt_TableHeadRow {
//     background: #F8F9FC !important;
// }

// .dzu-dropzone {
//     margin-top: 5px;
//     border: 2px dashed #D3178A !important;
//     background: unset;
//     border-radius: 16px;
//     overflow: auto !important;
// }

// .dzu-inputLabel {
//     color: #0B0B0B !important;
//     font-weight: 400;
//     font-size: 16px;
// }

// .dzu-inputLabelWithFiles {
//     background-color: #D3178A !important;
// }

// .dzu-submitButton {
//     background-color: #D3178A !important;
// }

// .dropzone {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 200px;
//     border: 2px dashed #D3178A;
//     padding: 2rem;
//     border-radius: 20px;

//     h1 {
//         font-family: 'Lato';
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 22px;
//         letter-spacing: 0em;
//     }

//     button {
//         box-shadow: 1px 1px 3px 0px #0000000F;
//         border: 1px solid #E1E6EF;
//         font-family: 'Lato';
//         font-size: 14px;
//         font-weight: 600;
//         letter-spacing: 0em;
//         padding: 10px 15px;
//         border-radius: 10px;
//     }

// }

// .previews {
//     ul {
//         li {
//             border: 1px solid #E1E6EF;
//             padding: 10px;
//             border-radius: 10px;
//         }
//     }
// }


.Save_Btn {
    border-radius: 12px;
    background: #D3178A;
    color: yellow;
}


.image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center images horizontally */
}

.image-item {
    margin: 10px 0;
    /* Space between images */
    max-width: 100%;
    /* Ensure images don't exceed container width */
    height: auto;
    /* Maintain aspect ratio */
}