.store_card {
    box-shadow: 0px 4px 16px 0px #11111A0D;
    border: 1px solid #E1E6EF;
    // transition: filter 0.3s ease-in-out;
}

// .inactiveCard {
//     // filter: blur(0.8px);
//     position: relative;
//     /* Ensure the parent is positioned */
//     width: 300px;
//     height: 200px;
//     background: lightgray;
//     overflow: hidden;
//     filter: blur(0.1px);
//     /* Apply blur to the parent */
// }

// .inactive_item {
//     // filter: none;
//     // z-index: 1;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background: white;
//     padding: 10px;
//     z-index: 5;
//     /* Ensure the child is above the blurred parent */
//     filter: none;
//     /* Remove blur effect from the child */
// }

.store_logo_img {
    width: 40px;
    height: 40px;
}

.store_card_outer {
    .store_card {
        .card-body {
            padding: 15px;
            min-height: 178px;
        }
    }

    .media-body {
        padding-left: 10px;

        h6 {
            display: flex;
            font-size: 15px;
            justify-content: space-between;
            align-items: center;
            margin: 0;
        }

        // svg {
        //     color: #1D2433;
        // }


        .total_pro {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #1D2433CC;
            margin-bottom: 5px;
        }

        .store_text {
            color: rgba(29, 36, 51, 0.8);
            font-size: 13px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: calc(2 * 1.65em);
        }

        .viewStore_btn {
            display: flex;
            align-items: center;
            box-shadow: 1px 1px 3px 0px #0000000F;
            border: 1px solid #E1E6EF !important;
            background: #FFFFFF !important;
            color: #1D2433 !important;
            font-size: 12px;
            border-radius: 12px;
            padding: 5px 20px !important;

            svg {
                width: 15px;
                margin-left: 8px;
            }
        }
    }
}

.store_img_wrapper {
    h6 {
        font-size: 14px;
    }

    p {
        font-size: 13px;
        color: #1D2433A6;
    }
}

.upload_label {
    box-shadow: 0px -2px 2px 0px #00000026 inset;
    color: #fff;
    background: #D3178A;
    border-radius: 12px;
    padding: 8px 30px;

    input {
        display: none;
    }
}

.store_modal {
    .modal-content {
        border-radius: 20px;
    }

    .modal-header {
        border-bottom: 1px solid #E1E6EF;

        .btn-close {
            position: unset;
            font-size: 10px;
        }
    }

    .css-13cymwt-control {
        padding: 0 !important;
    }
}

.modal_btm {
    padding: 15px 0 0 0;
    border-top: 1px solid #E1E6EF;
}

.autocomplete-dropdown-container {
    padding: 15px;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 1px 3px 0px #0000000D;
    position: absolute;
    z-index: 1;
}

.dark-only {
    .autocomplete-dropdown-container {
        background: #23272F;
    }
}

.store_address {
    input {
        width: 100%;
    }
}

.autocomplete-dropdown-container {
    div {
        border-bottom: 1px solid #E1E6EF;
        padding: 7px 0;
        width: 100%;
    }

}